import React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: -10
  },
  spacer: {
    height: '15px'
  },
  logo: {
    maxWidth: '120px',
    marginLeft: '-10px',
    marginRight: '10px'
  }
})

const CustomAppBar = props => {
  const classes = useStyles()
  return (
    <>
      <AppBar {...props} color='white'>
        <Toolbar>
          <img src='/assets/images/logo-dark.png' alt='logo' className={classes.logo} />
        </Toolbar>
        <Typography
          variant='h6'
          color='secondary'
          className={classes.title}
          id='react-admin-title'
        />
      </AppBar>
      <div className={classes.spacer} />
    </>
  )
}

export default CustomAppBar
