import React from 'react'
import {
  Datagrid, TextField, List, EmailField,
  Edit, Create, SimpleForm, TextInput, useGetIdentity
  , Pagination, BooleanInput, regex
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />
)

const validateLink = regex(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Must be a valid URL, e.g. https://example.com/something/file.jpg')

export const ProviderList = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  if (identity.roles === undefined) {
    return <>Forbidden</>
  }
  const filter = identity.roles.includes('provider')
    ? {
        ids: [identity.provider_id]
      }
    : {}
  return (
    <List
      {...props} perPage={500}
      pagination={<MyPagination />} filter={filter}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name' sortable={false} />
        <EmailField source='email' sortable={false} />
      </Datagrid>
    </List>
  )
}

export const ProviderEdit = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='name' />
        {identity.roles.includes('admin')
          ? <TextInput source='anonymised_name' />
          : <TextField source='anonymised_name' />}
        <TextInput source='motto' />
        <TextInput source='description' />
        <RichTextInput source='description_long' />
        <TextInput source='logo' validate={validateLink} />
        <TextInput source='logo_background' />
        <TextInput source='email' />
        <TextInput source='website' />
        <TextInput source='phone' />
        <TextInput source='contact_first_name' />
        <TextInput source='contact_last_name' />
        <TextInput source='contact_email' />
        {identity.roles.includes('admin')
          ? <BooleanInput source='promoted' label='Display' />
          : <></>}
      </SimpleForm>
    </Edit>
  )
}

export const ProviderCreate = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  return (
    <Create {...props}>
      {identity.roles.includes('admin')
        ? <SimpleForm>
          <TextInput source='name' />
          <TextInput source='anonymised_name' />
          <TextInput source='motto' />
          <TextInput source='description' />
          <RichTextInput source='description_long' />
          <TextInput source='logo' validate={validateLink} />
          <TextInput source='logo_background' />
          <TextInput source='email' />
          <TextInput source='website' />
          <TextInput source='phone' />
          <TextInput source='contact_first_name' />
          <TextInput source='contact_last_name' />
          <TextInput source='contact_email' />
          {identity.roles.includes('admin')
            ? <BooleanInput source='promoted' label='Display' />
            : <></>}
        </SimpleForm>
        : <div>
          You are not allowed to create a new provider
          </div>}
    </Create>
  )
}
