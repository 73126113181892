// In theme.js
import { defaultTheme } from 'react-admin'
import { createTheme } from '@material-ui/core/styles'
import merge from 'lodash/merge'

export const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      secondary: {
        main: '#222'
      },
      primary: {
        main: '#e51b23'
      }
    }
  })
)
