
const authProvider = ({
  isAuthenticated,
  loginWithRedirect,
  logout,
  user
}) => ({
  login: loginWithRedirect,
  logout: () => logout({ returnTo: window.location.origin }),
  checkError: () => Promise.resolve(),
  checkAuth: () => (isAuthenticated ? Promise.resolve() : Promise.reject(Error)),
  getPermissions: async () => {
    return user['http://trinity-cap.com/roles']
  },
  getIdentity: () =>
    Promise.resolve({
      id: user.id,
      email: user.email,
      fullName: user.name,
      avatar: user.picture,
      roles: user['http://trinity-cap.com/roles'],
      provider_id: user['http://trinity-cap.com/provider_id']
    })
})

export default authProvider
