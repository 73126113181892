import axios from 'axios'
import * as qs from 'qs'
import config from './../config'

const ROOT = config.api.ROOT

export async function getList (resource, { ids, pagination = {}, filter = {} }) {
  const token = sessionStorage.getItem('token')
  const resp = await axios(
    ROOT + `/${resource}`,

    {
      params: {
        ids: ids,
        page: pagination.page,
        size: pagination.perPage,
        ...filter
      },
      headers: {
        Authorization: 'Bearer ' + token
      },
      validateStatus: () => true,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  )
  return { data: resp.data.items, total: (resp.data.total === undefined) ? 0 : resp.data.total }
}

export async function getOne (resource, { id }) {
  const token = sessionStorage.getItem('token')
  const resp = await axios(
    ROOT + `/${resource}/${id}`,

    {
      headers: {
        Authorization: 'Bearer ' + token
      },
      validateStatus: () => true,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  )
  return { data: resp.data }
}

export async function deleteOne (resource, { id }) {
  const token = sessionStorage.getItem('token')
  const resp = await axios.delete(
    ROOT + `/${resource}/${id}`,
    {
      headers: {
        Authorization: 'Bearer ' + token
      },
      validateStatus: () => true,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  )
  return { data: resp.data }
}

export async function deleteMany (resource, { ids }) {
  const token = sessionStorage.getItem('token')
  const query = ids.join()
  const resp = await axios.delete(
    ROOT + `/${resource}?ids=` + query,
    {
      headers: {
        Authorization: 'Bearer ' + token
      },
      validateStatus: () => true,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  )
  return { data: resp.data }
}

export async function updateOne (resource, { data, id }) {
  const token = sessionStorage.getItem('token')
  const resp = await axios.put(
    ROOT + `/${resource}/${id}`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token
      },
      validateStatus: () => true,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  )
  return { data: resp.data }
}

export async function create (resource, { data }) {
  const token = sessionStorage.getItem('token')
  const resp = await axios.post(
    ROOT + `/${resource}`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token
      },
      validateStatus: () => true,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  )
  return { data: resp.data }
}

export async function postPrices (certID, file) {
  const token = sessionStorage.getItem('token')
  const formData = new FormData()
  formData.append('file', file)
  let respContent
  await axios.post(ROOT + `/certificates/${certID}/prices`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  })
    .then(async function (response) {
      console.log('trigger')
      respContent = { isFail: false, message: '', status: response.status }
    })
    .catch(async function (error) {
      console.log('trigger_catch')
      if (error.response) {
        respContent = { isFail: true, message: error.response.data.message, status: error.response.status }
        return
      }
      respContent = { isFail: true, message: 'unknown error', status: 0 }
    })
  return respContent
}

export async function computeKPIs () {
  const token = sessionStorage.getItem('token')
  return axios.get(ROOT + '/certificates/recompute', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function refreshCertificates () {
  const token = sessionStorage.getItem('token')
  return axios.get(ROOT + '/certificates/refresh', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}
