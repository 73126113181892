import { getList, getOne, updateOne, create, deleteOne, deleteMany } from './api'

const dataProvider = () => ({
  getList: (resource, params) => {
    return getList(resource, params)
  },
  getOne: (resource, id) => getOne(resource, id),
  getMany: (resource, params) => {
    return getList(resource, params)
  },
  getManyReference: (resource, params) => {
    return getList(resource, params)
  },
  update: (resource, params) => updateOne(resource, params),
  create: (resource, params) => create(resource, params),
  updateMany: (resource, params) => Promise,
  delete: (resource, id) => deleteOne(resource, id),
  deleteMany: (resource, ids) => deleteMany(resource, ids)
})

export default dataProvider
