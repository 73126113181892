import React from 'react'
import {
  Datagrid, TextField, List, DateField, BooleanField,
  Edit, SimpleForm, TextInput, BooleanInput, Create, useGetIdentity, Pagination
} from 'react-admin'

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />
)

export const TagList = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  if (identity.roles === undefined) {
    return <>Forbidden</>
  }
  return (
    <>
      <List
        {...props}
        perPage={500}
        pagination={<MyPagination />}
      >
        <Datagrid rowClick='edit'>
          <TextField source='name' sortable={false} />
          <DateField source='created_at' sortable={false} />
        </Datagrid>
      </List>
    </>
  )
}

export const TagEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source='name' />
      <DateField source='created_at' />
    </SimpleForm>
  </Edit>
)

export const TagCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='name' />
      </SimpleForm>
    </Create>
  )
}
