import React from 'react'
import { Datagrid, List, TextField, useGetIdentity, Pagination } from 'react-admin'

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />
)

export const UsersList = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  if (identity.roles === undefined) {
    return <>Forbidden</>
  }
  return (
    <>
      <List
        {...props}
        perPage={500}
        pagination={<MyPagination />}
      >
        <Datagrid rowClick='edit'>
          <TextField source='first_name' sortable={false} />
          <TextField source='last_name' sortable={false} />
          <TextField source='email_registered' sortable={false} />
          <TextField source='email_contact' sortable={false} />
          <TextField source='phone' sortable={false} />
          <TextField source='company_name' sortable={false} />
          <TextField source='company_type_name' sortable={false} />
          <TextField source='company_role' sortable={false} />
        </Datagrid>
      </List>
    </>
  )
}
