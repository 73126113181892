const dev = {
  oauth0: {
    DOMAIN: 'login.myamc.ch',
    CLIENTID: 'qPvJWiwK1Se5jSvKvgDELrr0bMLjruyJ'
  },
  api: {
    ROOT: '/api'
  }
}

const prod = {
  oauth0: {
    DOMAIN: 'login.myamc.ch',
    CLIENTID: 'qPvJWiwK1Se5jSvKvgDELrr0bMLjruyJ'
  },
  api: {
    ROOT: 'https://amc-prod-we-wa.azurewebsites.net/api'
  }
}

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}
