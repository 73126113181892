import React from 'react'
import {
  Datagrid, TextField, List, EmailField,
  Edit, Create, SimpleForm, TextInput, useGetIdentity, Pagination, BooleanInput, regex
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />
)

const validateLink = regex(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Must be a valid URL, e.g. https://example.com/something/file.jpg')

export const IssuerPlatformList = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  if (identity.roles === undefined) {
    return <>Forbidden</>
  }
  const filter = identity.roles.includes('issuer')
    ? {
        ids: [identity.issuer_id]
      }
    : {}
  return (
    <List
      {...props}
      perPage={500}
      pagination={<MyPagination />} filter={filter}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name' sortable={false} />
        <TextField source='website' sortable={false} />
      </Datagrid>
    </List>
  )
}

export const IssuerPlatformEdit = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  return (
    <Edit {...props}>
      {identity.roles.includes('admin')
        ? <SimpleForm>
          <TextInput source='name' />
          <TextInput source='motto' />
          <TextInput source='description' />
          <RichTextInput source='description_long' />
          <TextInput source='logo' validate={validateLink} />
          <TextInput source='logo_background' />
          <TextInput source='email' />
          <TextInput source='website' />
          <TextInput source='phone' />
          <TextInput source='contact_first_name' />
          <TextInput source='contact_last_name' />
          <TextInput source='contact_email' />
          <BooleanInput source='promoted' label='Display' />
          </SimpleForm>
        : <div>
          You are not allowed to edit an issuer
          </div>}
    </Edit>
  )
}

export const IssuerPlatformCreate = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  return (
    <Create {...props}>
      {identity.roles.includes('admin')
        ? <SimpleForm>
          <TextInput source='name' />
          <TextInput source='motto' />
          <TextInput source='description' />
          <RichTextInput source='description_long' />
          <TextInput source='logo' validate={validateLink} />
          <TextInput source='logo_background' />
          <TextInput source='email' />
          <TextInput source='website' />
          <TextInput source='phone' />
          <TextInput source='contact_first_name' />
          <TextInput source='contact_last_name' />
          <TextInput source='contact_email' />
          <BooleanInput source='promoted' label='Display' />
        </SimpleForm>
        : <div>
          You are not allowed to create a new issuer
          </div>}
    </Create>
  )
}
