import { Admin, Resource } from 'react-admin'
import dataProvider from './DataProvider/DataProvider'
import {
  CertificateCreate,
  CertificateEdit,
  CertList,
  ComputeKPI,
  RefreshCertificate,
  UploadCertPrices
} from './Components/Certs'
import { TagCreate, TagEdit, TagList } from './Components/Tags'
import { ThemeCreate, ThemeEdit, ThemeList } from './Components/Themes'
import { ProviderCreate, ProviderEdit, ProviderList } from './Components/Providers'
import { IssuerFirmCreate, IssuerFirmEdit, IssuerFirmList } from './Components/IssuerFirms'
import { IssuerPlatformCreate, IssuerPlatformEdit, IssuerPlatformList } from './Components/IssuerPlatforms'
import { useAuth0, withAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import authProvider from './AuthProvider'
import { Route } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { theme } from './theme'
import CustomLayout from './layout'
import { UsersList } from './Components/Users'

const App = () => {
  const {
    getAccessTokenSilently, isAuthenticated,
    logout,
    loginWithRedirect,
    user
  } = useAuth0()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    // we cache token in session store. if
    // TODO: figure out how and when to invalidate it
    if (window.sessionStorage.getItem('token') === null) {
      getAccessTokenSilently().then(t => {
        window.sessionStorage.setItem('token', t)
        setIsLoading(false)
      }).catch(e => {
        console.log(e)
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [getAccessTokenSilently])

  if (isLoading) {
    return (<CircularProgress />)
  }
  const customAuthProvider = authProvider({
    isAuthenticated,
    loginWithRedirect,
    logout,
    user
  })
  const customRoutes = [
    <Route path='/certificates/:certID/prices' component={UploadCertPrices} />,
    <Route path='/certificates/compute' component={ComputeKPI} />,
    <Route path='/certificates/refresh' component={RefreshCertificate} />

  ]
  return (
    <Admin
      layout={CustomLayout} authProvider={customAuthProvider} dataProvider={dataProvider()}
      customRoutes={customRoutes} theme={theme}
    >
      <Resource name='certificates' list={CertList} edit={CertificateEdit} create={CertificateCreate} />
      <Resource
        name='providers' options={{ label: 'Allocators' }} list={ProviderList} edit={ProviderEdit}
        create={ProviderCreate}
      />
      <Resource name='tags' list={TagList} edit={TagEdit} create={TagCreate} />
      <Resource name='themes' list={ThemeList} edit={ThemeEdit} create={ThemeCreate} />
      <Resource name='issuerfirms' options={{ label: 'Issuer Firms' }} list={IssuerFirmList} edit={IssuerFirmEdit} create={IssuerFirmCreate} />
      <Resource name='issuerplatforms' options={{ label: 'Issuer Platforms' }} list={IssuerPlatformList} edit={IssuerPlatformEdit} create={IssuerPlatformCreate} />
      <Resource name='users' list={UsersList} />
      <Resource name='frequencies' />
      <Resource name='benchmarks' />
      <Resource name='investmenttypes' options={{ label: 'Investment Types' }} />
      <Resource name='strategystyles' options={{ label: 'Strategy Styles' }} />
      <Resource name='strategytypes' options={{ label: 'Strategy Types' }} />
      <Resource name='assetclasses' options={{ label: 'Asset Classes' }} />
      <Resource name='redemptionnotices' options={{ label: 'Redemption Notices' }} />
      <Resource name='companytypes' options={{ label: 'Company Types' }} />
    </Admin>
  )
}

export default withAuthenticationRequired(withAuth0(App))
