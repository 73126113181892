import React, { cloneElement, useState } from 'react'
import {
  BooleanInput,
  Button as RaButton,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateTimeInput,
  Edit,
  ExportButton,
  List,
  Loading,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  required,
  sanitizeListRestProps,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Title,
  TopToolbar,
  useGetIdentity,
  useListContext,
  regex,
  Pagination
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { Button, Card, CardContent, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { Link, useParams } from 'react-router-dom'
import { computeKPIs, postPrices, refreshCertificates } from '../../DataProvider/api'

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />
)

const validateLink = regex(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Must be a valid URL, e.g. https://example.com/something/file.pdf')

const TextArrayField = ({ record, source }) => {
  const array = record[source]
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />
  } else {
    return (
      <>
        {array.map(item => <div key={item}>{item}</div>)}
      </>
    )
  }
}
TextArrayField.defaultProps = { addLabel: true }

export const ComputeKPI = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFail, setIsFail] = useState(false)

  const handleSubmit = (event) => {
    setIsLoading(true)
    computeKPIs()
      .then(() => setIsSuccess(true))
      .catch(() => setIsFail(true))
      .finally(() => setIsLoading(false))
  }
  if (isLoading) {
    return (<CircularProgress />)
  }
  if (isSuccess) {
    return (<Alert severity='success'>Successfully computed certificates' KPI</Alert>)
  }
  if (isFail) {
    return (<Alert severity='error'>Something went wrong</Alert>)
  }
  return (
    <Card>
      <Title title='Compute KPI' />
      <CardContent>
        <Button variant='contained' color='primary' onClick={handleSubmit}>Compute All</Button>
      </CardContent>
    </Card>
  )
}

export const RefreshCertificate = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFail, setIsFail] = useState(false)

  const handleSubmit = (event) => {
    setIsLoading(true)
    refreshCertificates()
      .then(() => setIsSuccess(true))
      .catch(() => setIsFail(true))
      .finally(() => setIsLoading(false))
  }
  if (isLoading) {
    return (<CircularProgress />)
  }
  if (isSuccess) {
    return (<Alert severity='success'>Successfully queued certificate refresh</Alert>)
  }
  if (isFail) {
    return (<Alert severity='error'>Something went wrong</Alert>)
  }
  return (
    <Card>
      <Title title='Refresh Data' />
      <CardContent>
        <Button variant='contained' color='primary' onClick={handleSubmit}>Refresh All</Button>
      </CardContent>
    </Card>
  )
}

export const UploadCertPrices = () => {
  const [selectedFile, setSelectedFile] = useState({ empty: true })
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [uploadMessage, setUploadMessage] = useState('')
  const [isFail, setIsFail] = useState(false)
  const { certID } = useParams()
  const handleChange = (event) => {
    setSelectedFile(event.target.files[0])
  }
  const handleSubmit = (event) => {
    setIsLoading(true)
    postPrices(certID, selectedFile)
      .then(d => {
        switch (d.status) {
          case 200:
          case 201:
            setIsSuccess(true)
            break
          default:
            setIsFail(true)
        }
        setUploadMessage(d.message)
      })
      .finally(() => setIsLoading(false))
  }
  if (isLoading) {
    return (<CircularProgress />)
  }
  if (isSuccess) {
    return (<Alert severity='success'>Successfully uploaded certificate prices</Alert>)
  }
  if (isFail) {
    return (<Alert severity='error'>Something went wrong: {uploadMessage}</Alert>)
  }
  return (
    <Card>
      <Title title='Upload Certificate Price History' />
      <CardContent>
        <label htmlFor='btn-upload'>
          <input
            id='btn-upload' name='btn-upload' style={{ display: 'none' }} type='file'
            onChange={handleChange}
          />
          {!selectedFile.empty
            ? (
              <div>
                <p>Filename: {selectedFile.name}</p>
              </div>
              )
            : (
              <p>Select a file to show details</p>
              )}
          <Button className='btn-choose' variant='outlined' component='span'>Choose Files</Button>
        </label>
        <Button
          variant='contained' color='primary' disabled={selectedFile.empty}
          onClick={handleSubmit}
        >Send
        </Button>
      </CardContent>
    </Card>
  )
}

export const ListActions = (props) => {
  const { identity = { roles: [] } } = useGetIdentity()
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total
  } = useListContext()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {identity.roles.includes('admin')
        ? <>
          <Link to='/certificates/compute'><RaButton label='Compute KPI' /></Link>
          <Link to='/certificates/refresh'><RaButton label='Refresh Data' /></Link>
        </>
        : ''}
    </TopToolbar>
  )
}

const CertEditActions = ({ data }) => {
  if (data === undefined) {
    return (<Alert>Price Upload Unavailable</Alert>)
  }
  return (
    <TopToolbar>
      <Link to={`/certificates/${data.id}/prices`}><Button variant='contained' color='primary'> Upload
        prices
      </Button>
      </Link>
    </TopToolbar>
  )
}

export const CertList = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  if (identity.roles === undefined) {
    return <>Forbidden</>
  }
  const filter = identity.roles.includes('provider')
    ? {
        provider_ids: [identity.provider_id]
      }
    : {}

  if (identity.roles.length === 0) {
    return (<Loading />)
  }
  return (
    <List
      {...props} perPage={500}
      pagination={<MyPagination />} actions={<ListActions />} filter={filter}
    >
      <Datagrid rowClick='edit'>
        <TextField source='name' sortable={false} />
        <TextField label='ISIN' source='original_isin' sortable={false} />
        <TextField label='Valor' source='original_valoren' sortable={false} />
        <ReferenceField source='provider_id' reference='providers' sortable={false}>
          <TextField source='name' />
        </ReferenceField>
        <TextField label='Issuer' source='issuer' sortable={false} />
        <ReferenceArrayField label='Tags' source='tag_ids' reference='tags' sortable={false}>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  )
}

export const CertificateEdit = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  return (
    <Edit actions={<CertEditActions />} {...props}>
      <SimpleForm>

        <TextInput source='name' validate={required()} />
        {identity.roles.includes('admin')
          ? <TextInput source='anonymised_name' />
          : <TextField source='anonymised_name' />}
        <RichTextInput source='description' />
        <RichTextInput source='objective' />
        <RichTextInput source='policy' />
        <RichTextInput source='note' />
        <TextInput source='original_isin' />
        <TextInput source='original_valoren' />

        <TextInput source='six_id' disabled label='SIX ID' />
        {identity.roles.includes('admin')
          ? <TextInput source='six_id_override' label='Override SIX ID with ...' />
          : <></>}

        {identity.roles.includes('admin')
          ? <TextArrayField source='six_alts' label='SIX Alternatives' />
          : <></>}

        <ReferenceInput source='benchmark_id' reference='benchmarks' allowEmpty>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <TextInput source='currency' />
        <DateTimeInput source='inception_date' />
        <DateTimeInput source='expiration_date' />
        <NumberInput source='denomination' />
        <TextInput source='region' />
        <TextInput source='link_factsheet' validate={validateLink} />
        <TextInput source='link_prospectus' validate={validateLink} />
        <TextInput source='link_terms' validate={validateLink} />

        <BooleanInput source='esg' label='ESG Compliant' />
        <BooleanInput source='quant' label='Quantitative Approach' />

        <ReferenceInput source='investment_type_id' reference='investmenttypes'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <ReferenceInput perPage={1000} label='Continues from' source='parent_id' reference='certificates'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <NumberInput source='correction_factor' />

        <ReferenceInput source='strategy_style_id' reference='strategystyles'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='strategy_type_id' reference='strategytypes'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='asset_class_id' reference='assetclasses'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <h3>Stakeholders</h3>
        <h6>Provider</h6>
        {identity.roles.includes('admin')
          ? <ReferenceInput perPage={1000} label='Provider' source='provider_id' reference='providers' validate={required()}>
            <SelectInput optionText='name' />
            </ReferenceInput>
          : <ReferenceField source='provider_id' reference='providers' validate={required()}>
            <TextField source='name' />
            </ReferenceField>}

        <h6>Issuer</h6>
        <ReferenceInput perPage={1000} label='Issuer' source='issuer_id' reference='issuerfirms' allowEmpty>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput source='issuer' label='Alternative issuer firm' />

        <h6>Issuer Platform</h6>
        <ReferenceInput perPage={1000} label='Issuer Platform' source='issuer_platform_id' reference='issuerplatforms' allowEmpty>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput source='issuer_platform' label='Alternative issuer platform' />

        <h6>Others</h6>
        <TextInput source='calculating_agent' />

        <TextInput source='paying_agent' />
        <TextInput source='broker' />
        <TextInput source='crypto_custodian' />

        <h3>Liquidity</h3>
        <TextInput source='mini_initial_subscription' label='Minimum Initial Subscription' />
        <ReferenceInput source='subscription_frequency' reference='frequencies'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='redemption_frequency' reference='frequencies'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='redemption_notice' reference='redemptionnotices'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <h3>Fees</h3>
        <NumberInput source='issuance_fee' />
        <NumberInput source='distribution_fee' />
        <NumberInput source='management_fee' />
        <NumberInput source='performance_fee' />
        <BooleanInput source='performance_fee_hwm' label='including High Water Mark' />
        <NumberInput source='platform_fee' />
        <NumberInput source='admin_fee' />
        <NumberInput source='custody_fee' />
        <NumberInput source='storage_fee' />
        <NumberInput source='general_expense' />
        <NumberInput source='misc_fee' />
        <TextInput source='misc_fee_detail' />
        <NumberInput source='sec_market_spread' />

        <h3>Tags</h3>
        <ReferenceArrayInput perPage={1000} source='tag_ids' reference='tags'>
          <SelectArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput perPage={1000} source='themes_ids' reference='themes'>
          <SelectArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceInput perPage={1000} source='theme_main_id' reference='themes'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <BooleanInput source='promoted' label='Display' />
      </SimpleForm>
    </Edit>
  )
}

export const CertificateCreate = props => {
  const { identity = { roles: [] } } = useGetIdentity()
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='name' validate={required()} />
        {identity.roles.includes('admin')
          ? <TextInput source='anonymised_name' />
          : <TextField source='anonymised_name' />}
        <RichTextInput source='description' />
        <RichTextInput source='objective' />
        <RichTextInput source='policy' />
        <RichTextInput source='note' />
        <TextInput source='original_isin' />
        <TextInput source='original_valoren' />
        <TextInput source='currency' />
        <DateTimeInput source='inception_date' />
        <DateTimeInput source='expiration_date' />
        <NumberInput source='denomination' />
        <TextInput source='region' />
        <TextInput source='link_factsheet' validate={validateLink} />
        <TextInput source='link_prospectus' validate={validateLink} />
        <TextInput source='link_terms' validate={validateLink} />

        <BooleanInput source='esg' label='ESG Compliant' />
        <BooleanInput source='quant' label='Quantitative Approach' />

        <ReferenceInput source='investment_type_id' reference='investmenttypes'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput perPage={1000} label='Continues from' source='parent_id' reference='certificates'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <ReferenceInput source='strategy_style_id' reference='strategystyles'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='strategy_type_id' reference='strategytypes'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='asset_class_id' reference='assetclasses'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <h3>Stakeholders</h3>
        <h6>Provider</h6>
        {identity.roles.includes('admin')
          ? <ReferenceInput perPage={1000} label='Provider' source='provider_id' reference='providers' validate={required()}>
            <SelectInput optionText='name' />
            </ReferenceInput>
          : <ReferenceField source='provider_id' reference='providers' validate={required()}>
            <TextField source='name' />
            </ReferenceField>}

        <h6>Issuer</h6>
        <ReferenceInput perPage={1000} label='Issuer' source='issuer_id' reference='issuerfirms' allowEmpty>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput source='issuer' label='Alternative issuer firm' />

        <h6>Issuer Platform</h6>
        <ReferenceInput perPage={1000} label='Issuer Platform' source='issuer_platform_id' reference='issuerplatforms' allowEmpty>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput source='issuer_platform' label='Alternative issuer platform' />

        <h6>Others</h6>
        <TextInput source='calculating_agent' />
        <TextInput source='paying_agent' />
        <TextInput source='broker' />
        <TextInput source='crypto_custodian' />

        <h3>Liquidity</h3>
        <TextInput source='mini_initial_subscription' label='Minimum Initial Subscription' />
        <ReferenceInput source='subscription_frequency' reference='frequencies'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='redemption_frequency' reference='frequencies'>
          <SelectInput optionText='name' />
        </ReferenceInput>
        <ReferenceInput source='redemption_notice' reference='redemptionnotices'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <h3>Fees</h3>
        <NumberInput source='issuance_fee' />
        <NumberInput source='distribution_fee' />
        <NumberInput source='management_fee' />
        <NumberInput source='performance_fee' />
        <BooleanInput source='performance_fee_hwm' label='including High Water Mark' />
        <NumberInput source='platform_fee' />
        <NumberInput source='admin_fee' />
        <NumberInput source='custody_fee' />
        <NumberInput source='storage_fee' />
        <NumberInput source='general_expense' />
        <NumberInput source='misc_fee' />
        <TextInput source='misc_fee_detail' />
        <NumberInput source='sec_market_spread' />

        <h3>Tags</h3>
        <ReferenceArrayInput perPage={1000} source='tag_ids' reference='tags'>
          <SelectArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput perPage={1000} source='themes_ids' reference='themes'>
          <SelectArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceInput perPage={1000} source='theme_main_id' reference='themes'>
          <SelectInput optionText='name' />
        </ReferenceInput>

        <BooleanInput source='promoted' label='Display' defaultValue />
      </SimpleForm>
    </Create>
  )
}
